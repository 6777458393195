import "../styles/Footer.css"

export default function Footer()
{
    return (
        <>
            <div className="footer">
                <h1 className="footer-text">HOPE YOU HAD A GOOD TIME🚀</h1>
            </div>
        </>
    )
}